import { StatusCodes } from 'http-status-codes';
import { useAuthorization } from '~/composables/auth/useAuthorization';
import { useAuthStore } from '~/stores/auth/useAuthStore';

/** Middleware проверяет авторизацию. */
export default defineNuxtRouteMiddleware(async () => {
    const store = useAuthStore();

    if(!store.isAuthorized) {
        const { redirectToLogoutUrl } = useAuthorization();
        redirectToLogoutUrl();
    }

    if(!store.isAdministrator) {
        throw createError({ statusCode: StatusCodes.FORBIDDEN });
    }
})